import { BackstageTheme } from '@backstage/theme';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { AccessDenied } from './AccessDenied';

interface IErrorPageProps {
  status: string;
  statusMessage: string;
  additionalInfo?: React.ReactNode;
  supportUrl?: string;
}

/** @public */
export type ErrorPageClassKey = 'container' | 'title' | 'subtitle';

const useStyles = makeStyles<BackstageTheme>(
  theme => ({
    container: {
      padding: theme.spacing(8),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2),
      },
    },
    title: {
      paddingBottom: theme.spacing(5),
      [theme.breakpoints.down('xs')]: {
        paddingBottom: theme.spacing(4),
        fontSize: 32,
      },
    },
    subtitle: {
      color: theme.palette.textSubtle,
    },
  }),
  { name: 'BackstageErrorPage' },
);

/**
 * Error page with status and description
 *
 * @public
 *
 */
export function ErrorPage(props: IErrorPageProps) {
  const { status, statusMessage, additionalInfo } = props;
  const classes = useStyles();

  return (
    <Grid container spacing={0} className={classes.container}>
      <Grid item xs={12} sm={8} md={4}>
        <Typography
          data-testid="error"
          variant="body1"
          className={classes.subtitle}
        >
          ERROR {status}
        </Typography>
        <Typography variant="body1" className={classes.subtitle}>
          {additionalInfo}
        </Typography>
        <Typography variant="h2" className={classes.title}>
          {statusMessage}
        </Typography>
        <Typography variant="h4" className={classes.title}>
          Reach out to the #squad-platform Slack channel if this does not seem
          right.
        </Typography>
      </Grid>
      <AccessDenied />
    </Grid>
  );
}
