import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { ErrorPage } from './components/error/ErrorPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';
import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
} from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import {
  oktaAuthApiRef,
  configApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  BackstageTheme,
  lightTheme,
  darkTheme,
  shapes,
} from '@backstage/theme';
import {
  DefaultTechDocsHome,
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { PagerDutyPage } from '@pagerduty/backstage-plugin';
import { ScaffolderFieldExtensions } from '@backstage/plugin-scaffolder-react';
import { SelectFieldFromApiExtension } from '@roadiehq/plugin-scaffolder-frontend-module-http-request-field';

/**
 * The `@backstage/core-components` package exposes this type that
 * contains all Backstage and `material-ui` components that can be
 * overridden along with the classes key those components use.
 */
import { BackstageOverrides } from '@backstage/core-components';
import * as plugins from './plugins';

export const createCustomThemeOverrides = (
  _theme: BackstageTheme,
): BackstageOverrides => {
  const gradientColors = ['#0F0FE0', '#051064'];
  const gradient = `linear-gradient(90deg, ${gradientColors.join(', ')})`;
  const backgroundImage = `${shapes.wave},  ${gradient}`;
  return {
    BackstageHeader: {
      header: {
        backgroundImage: backgroundImage,
      },
    },
  };
};

const customLightTheme: BackstageTheme = {
  ...lightTheme,
  overrides: {
    // These are the overrides that Backstage applies to `material-ui` components
    ...lightTheme.overrides,
    // These are your custom overrides, either to `material-ui` or Backstage components.
    ...createCustomThemeOverrides(lightTheme),
  },
};

const customDarkTheme: BackstageTheme = {
  ...darkTheme,
  overrides: {
    // These are the overrides that Backstage applies to `material-ui` components
    ...darkTheme.overrides,
    // These are your custom overrides, either to `material-ui` or Backstage components.
    ...createCustomThemeOverrides(darkTheme),
  },
};

const app = createApp({
  apis,
  plugins: Object.values(plugins),
  themes: [
    {
      id: 'light-theme',
      title: 'Light Theme',
      variant: 'light',
      Provider: ({ children }) => (
        <ThemeProvider theme={customLightTheme}>
          <CssBaseline>{children}</CssBaseline>
        </ThemeProvider>
      ),
    },
    {
      id: 'dark-theme',
      title: 'Dark Theme',
      variant: 'dark',
      Provider: ({ children }) => (
        <ThemeProvider theme={customDarkTheme}>
          <CssBaseline>{children}</CssBaseline>
        </ThemeProvider>
      ),
    },
  ],
  components: {
    SignInPage: props => {
      return (
        <SignInPage
          {...props}
          auto
          align="center"
          providers={
            useApi(configApiRef).getString('auth.environment') === 'development'
              ? ['guest']
              : [
                  {
                    id: 'okta',
                    title: 'Okta',
                    message: 'Sign in using Okta',
                    apiRef: oktaAuthApiRef,
                  },
                ]
          }
        />
      );
    },
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<Navigate to="catalog" />} />
    <Route
      path="/catalog"
      element={<CatalogIndexPage initiallySelectedFilter="all" />}
    />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route
      path="/create"
      element={
        <RequirePermission
          permission={catalogEntityCreatePermission}
          errorPage={
            <ErrorPage status="403" statusMessage="Not enough permissions." />
          }
        >
          <ScaffolderPage
            groups={[
              {
                title: 'Backend',
                filter: entity =>
                  entity?.metadata?.tags?.includes('backend') ?? false,
              },
              {
                title: 'Frontend',
                filter: entity =>
                  entity?.metadata?.tags?.includes('frontend') ?? false,
              },
              {
                title: 'Packages',
                filter: entity =>
                  entity?.metadata?.tags?.includes('package') ?? false,
              },
            ]}
          />
        </RequirePermission>
      }
    >
      <ScaffolderFieldExtensions>
        <SelectFieldFromApiExtension />
      </ScaffolderFieldExtensions>
    </Route>
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route
      path="/catalog-graph"
      element={
        <CatalogGraphPage
          initialState={{
            maxDepth: 2,
            selectedKinds: [
              'API',
              'Component',
              'Group',
              'Location',
              'Template',
            ],
            selectedRelations: [
              'ownedBy',
              'consumesApi',
              'providesApi',
              'childOf',
              'parentOf',
            ],
          }}
        />
      }
    />
    <Route path="/docs" element={<TechDocsIndexPage />}>
      <DefaultTechDocsHome />
    </Route>
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route path="/pagerduty" element={<PagerDutyPage />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
