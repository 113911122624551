/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';

const LogoFull = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="171"
      height="48"
      viewBox="0 0 171 48"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FFF"
          d="M0 0L0 48 114.978 48 123.16 45.219 166.386 48 167.543 30.134 171 28.959 171 0z"
        />
        <g fill="#0E0FE0">
          <path
            d="M15.493 13.464c1.55 0 2.688-.247 3.415-.742.728-.494 1.092-1.387 1.092-2.678 0-.739-.142-1.302-.426-1.691-.285-.389-.679-.652-1.181-.791-.504-.139-1.146-.208-1.927-.208h-2.581l-1.072 6.11h2.68zM5.188.394h12.787c2.436 0 4.544.271 6.325.811 1.78.54 3.2 1.506 4.259 2.897 1.059 1.39 1.588 3.292 1.588 5.704 0 3.77-1.254 6.466-3.763 8.087-2.508 1.621-6.006 2.432-10.493 2.432h-4.31l-1.488 8.424H.185L5.188.394zM45.026 17.043l-.337-3.56c-.212-2.28-.358-4.243-.437-5.892h-.04l-.139.347c-.04.098-.08.188-.12.266-.9 2.202-1.64 3.962-2.223 5.28l-1.588 3.559h4.884zm.695 7.237h-8.816L34.9 28.748H24.197L38.831.394h12.927l4.586 28.354H46.158l-.437-4.468zM73.925 13.464c1.55 0 2.688-.247 3.416-.742.728-.494 1.092-1.387 1.092-2.678 0-.739-.143-1.302-.427-1.691-.285-.389-.678-.652-1.181-.791-.504-.139-1.145-.208-1.926-.208h-2.582l-1.072 6.11h2.68zM63.621.394h12.786c2.436 0 4.545.271 6.325.811 1.78.54 3.2 1.506 4.259 2.897 1.059 1.39 1.588 3.292 1.588 5.704 0 3.77-1.254 6.466-3.762 8.087-2.51 1.621-6.007 2.432-10.494 2.432h-4.31l-1.488 8.424h-9.908L63.62.394zM92.661.394L116.19.394 114.964 7.354 101.481 7.354 100.842 11.111 112.934 11.111 111.723 17.854 99.631 17.854 98.975 21.611 112.418 21.611 111.166 28.748 87.657 28.748zM126.724 12.99l.992-5.636h2.78c.834 0 1.5.053 1.996.159.496.105.88.326 1.151.662.272.336.407.84.407 1.513 0 1.239-.37 2.099-1.112 2.58-.741.481-1.873.722-3.395.722h-2.82zm4.767 9.035c0 .304-.02.633-.06.99-.026.21-.04.362-.04.454-.118.923-.178 1.674-.178 2.254 0 1.194.217 2.198.65 3.013l9.626-3.268c-.03-.34-.05-.686-.05-1.05 0-.66.04-1.338.119-2.037.04-.381.06-.738.06-1.067 0-2.4-.98-4.06-2.94-4.983 3.893-1.292 5.838-3.921 5.838-7.89 0-2.807-.956-4.85-2.87-6.128-1.912-1.28-4.523-1.919-7.832-1.919h-14.793l-5.003 28.354h9.908l1.668-9.293h2.64c1.165 0 2 .191 2.502.574.503.382.755 1.047.755 1.996z"
            transform="translate(11.565 9.103)"
          />
        </g>
      </g>
    </svg>
  );
};

export default LogoFull;
