import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AccessDeniedSvgUrl from './access-denied.svg';

const useStyles = makeStyles(
  theme => ({
    accessDenied: {
      maxWidth: '60%',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        maxWidth: '96%',
        bottom: 'unset',
        right: 'unset',
        margin: `${theme.spacing(10)}px auto ${theme.spacing(4)}px`,
      },
    },
  }),
  { name: 'BackstageErrorPageAccessDenied' },
);

export type AccessDeniedClassKey = 'accessDenied';

export const AccessDenied = () => {
  const classes = useStyles();
  return (
    <img
      src={AccessDeniedSvgUrl}
      className={classes.accessDenied}
      alt="Access denied"
    />
  );
};
